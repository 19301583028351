import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import style from "../../../assets/styles/modules/prelogin/prelogin-layout.module.scss";
import Password from "../../../components/Form/Password";
import SocialLogin from "../../../components/Form/SocialLogin";
import constants from "../../../config/constants";
import { api } from "../../../customAxios";

export default function Login() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter valid email address")
      .matches(constants.EMAIL_REGEX, "Please enter valid email address"),

    password: Yup.string()
      .required("Please enter your password")
      .min(6, "The password must be at least 6 characters"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      navigate("/user/dashboard");
    }
  }, [token]);
  const onSubmit = async (data) => {
    setIsLoading(true);
    await api
      .post("/login", data)
      .then((res) => {
        localStorage.setItem("token", res.data.user.token);
        localStorage.setItem("user",JSON.stringify( res.data.user));
        const redirectTo = queryParams.get("redirectTo");
        const destination = redirectTo
          ? decodeURIComponent(redirectTo)
          : "/user/dashboard";
        navigate(destination);
        setIsLoading(false);
        toast.success("You have successfully signed in");
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <form className={`${style.form_wrapper} prelogin`} onSubmit={handleSubmit(onSubmit)}>
        <h1 className={style.form_title}>Hi, Welcome back!</h1>

        <div className="form-group">
          <label className="text-left">Email</label>
          <input
            type="text"
            className={`form-control  ${errors.email ? style['is-invalid_boder'] : ""
            }`}
            placeholder="Enter Email Address"
            {...register("email")}
          />
          {errors.email && <span className={`${style['danger-color']} error-msg`}>{errors.email.message}</span>}
          </div>

        <Password
          id="password"
          name="password"
          label="Password"
          placeholder="Enter Password"
          {...register("password")}
          errorMessage={errors.password?.message}
        />
        <div className={`form-group ${style.forgotWrapper}`}>
          <div className="form-check checkbox">
            <input
              type="checkbox"
              id="chkRemember"
              className="custom-checkbox"
            />
            <label htmlFor="chkRemember" className="form-check-label">
              Remember Me
            </label>
          </div>
        </div>

        <p className="text-danger">{errorMessage}</p>
        <div className="form-group">
          <button className="btn btn-primary" type="submit" disabled={!isDirty || !isValid || isLoading}>
            {isLoading ? "Please wait..." : "Login"}
          </button>
        </div>

        <SocialLogin />
      </form>

      <p className={`${style.form_para} text-center`}>
        Don&apos;t have an account?{" "}
        <Link to="/auth/signup" className="fw-700 text-primary">
          Signup
        </Link>
      </p>
    </>
  );
}
