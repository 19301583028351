import React from "react";
import style from "../../assets/styles/modules/prelogin/social-login.module.scss";
import GoogleIcon from "../../assets/images/icons8-google.png";

export default function SocialLogin() {

  const handleSocialLogin = (type) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/login/${type}`,
      "_self"
    );
  };

  return (
    <div className={`form-group ${style.social_login_wrapper}`}>
      <div className={style.or_divider}>OR</div>
      <div className={style.buttons_container}>
        <button
          className={`${style.login_button} ${style.googlebtn}`}
          aria-label="Login with google"
          type="button"
          onClick={() => handleSocialLogin("google")}

        >
            <>
              <img src={GoogleIcon} alt="Google" />
              Google
            </>
        </button>
        {/* <button
          className={`${style.login_button} ${style.fbbtn}`}
          aria-label="Login with facebook"
          type="button"
        >
          <img src="/images/icons8-facebook.png" alt="Facebook" />
          Facebook
        </button>
        <button
          className={`${style.login_button} ${style.applebtn}`}
          aria-label="Login with apple"
          type="button"
        >
          <img src="/images/icons8-apple-24.png" alt="Apple" />
          Apple
        </button> */}
      </div>
    </div>
  );
}
