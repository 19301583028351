import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers['Accept'] = `application/json`;
    config.headers["Authorization"] = 'Bearer ' + localStorage.getItem("token");
    return config;
  },
)

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response?.status === 401) {
    // window.location.href =
    //  '/';
    localStorage.removeItem("token");
  }
  return Promise.reject(error);
});

export const api = {

  // get url use 
  get(url) {
    return axiosInstance.get(url)
  },
  // post url use 
  post(url, data) {
    return axiosInstance.post(url, data)
  },
  put(url, data) {
    return axiosInstance.put(url, data)
  },
  uploadFile(url, data, config = {}) {
    return axiosInstance.post(url, data, {
      ...config,
      onUploadProgress: config.onUploadProgress
    })
  },
  // delete url use 
  delete(url, id) {
    return axiosInstance.delete(url, id)
  }
};