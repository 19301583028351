import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { formatNumber } from "../../config/utils";
import { api } from "../../customAxios";
const ArtistChart = ({ selectedChannnelId, artistId, handleGetChartData }) => {
  const [selection, setSelection] = useState("all");
  const [chartHeight, setChartHeight] = useState(320);
  const [options, setOptions] = useState({
    chart: {
      id: "area-datetime",
      type: "area",
      height: chartHeight,
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: "",
          fontSize: "14px",
          fontWeight: 400,
        },
      },
    },
    yaxis: [
      {
        opposite: false,
        title: {
          text: "",
          style: {
            fontSize: "14px",
            color: "",
            fontWeight: 400,
          },
        },
        labels: {
          formatter: (num) => formatNumber(num),
          style: {
            colors: "#8197A9",
            fontSize: "14px",
          },
        },
      },
      {
        opposite: true,
        title: {
          text: "",
          style: {
            fontSize: "14px",
            color: "#8197A9",
            fontWeight: 400,
          },
        },
        labels: {
          formatter: (num) => formatNumber(num),
          style: {
            colors: "#8197A9",
            fontSize: "14px",
          },
        },
      },
    ],
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    stroke: {
      colors: ["#1ED760"],
      width: 2,
    },
    fill: {
      type: "gradient",
      colors: ["#9057FD"],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    legend: {
      show: false,
    },
  });

  const [series, setSeries] = useState([
    {
      data: [],
    },
    {
      data: [],
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getArtistHistoricalData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await api.get(
        `artists/${artistId}/channels/${selectedChannnelId}/data/chart?public=true`
      );

      const data = response.data;
      if (data) {
        handleGetChartData(data.data[0]);
        setOptions((prevOptions) => {
          const yaxis = Array.isArray(prevOptions.yaxis)
            ? prevOptions.yaxis
            : [];
          const strokeColors = data.data[0]?.seriesData[0].color;

          return {
            ...prevOptions,
            yaxis: [
              {
                ...(yaxis[0] || {}),
                title: {
                  ...(yaxis[0]?.title || {}),
                  text: data?.data[0]?.yAxisTitleData1?.title?.text || "",
                },
              },
              {
                ...(yaxis[1] || {}),
                title: {
                  ...(yaxis[1]?.title || {}),
                  text: data?.data[0]?.yAxisTitleData2?.title?.text || "",
                },
              },
            ],
            stroke: {
              colors: [strokeColors],
              width: 2,
            },
          };
        });
        let seriesData = data?.data[0]?.seriesData || [{
          data: [],
        },
        {
          data: [],
        }];
        if (seriesData.length === 1) {
          seriesData = [
            ...seriesData,
            {
              data: [],
            },
          ];
        }
        setSeries(seriesData);
      }
    } catch (error) {
      setError("An error occurred while fetching data.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (artistId && selectedChannnelId) {
      getArtistHistoricalData();
    }
  }, [selectedChannnelId, artistId]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1400) {
        setChartHeight(320);
      } else if (window.innerWidth <= 1400 && window.innerWidth > 768) {
        setChartHeight(200);
      } else {
        setChartHeight(150);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateData = (timeline) => {
    setSelection(timeline);
    let startDate, endDate;

    switch (timeline) {
      case "one_month":
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        endDate = new Date();
        break;
      case "six_months":
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 6);
        endDate = new Date();
        break;
      case "one_year":
        startDate = new Date();
        startDate.setMonth(0, 12);
        endDate = new Date();
        break;
      case "ytd":
        startDate = new Date();
        startDate.setMonth(0, 1);
        endDate = new Date();
        break;
      case "all":
        startDate = new Date("2019-08-23");
        endDate = new Date();
        break;
      default:
        return;
    }

    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        min: startDate.getTime(),
        max: endDate.getTime(),
      },
    }));
  };

  return (
    <div id="chart">
      <>
        <div className="toolbar" role="toolbar" aria-label="Chart time range">
          <button
            id="one_month"
            onClick={() => updateData("one_month")}
            className={selection === "one_month" ? "active" : ""}
            aria-pressed={selection === "one_month"}
          >
            1M
          </button>
          <button
            id="six_months"
            onClick={() => updateData("six_months")}
            className={selection === "six_months" ? "active" : ""}
            aria-pressed={selection === "six_months"}
          >
            6M
          </button>
          <button
            id="one_year"
            onClick={() => updateData("one_year")}
            className={selection === "one_year" ? "active" : ""}
            aria-pressed={selection === "one_year"}
          >
            1Y
          </button>
          <button
            id="ytd"
            onClick={() => updateData("ytd")}
            className={selection === "ytd" ? "active" : ""}
            aria-pressed={selection === "ytd"}
          >
            YTD
          </button>
          <button
            id="all"
            onClick={() => updateData("all")}
            className={selection === "all" ? "active" : ""}
            aria-pressed={selection === "all"}
          >
            ALL
          </button>
        </div>
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={chartHeight}
        />
      </>
    </div>
  );
};

export default ArtistChart;
