import { useNavigate, Outlet, useLocation } from "react-router-dom";
import style from "../../assets/styles/modules/dashboard/dashboard-layout.module.scss";
import SearchBar from "../SearchBar/SearchBar";
import SideBar from "../SideBar/SideBar";
import logo from '../../assets/images/logo.png';
import logo2 from '../../assets/images/logo2.png';

export default function DashboardLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignInClick = () => {
    navigate("/auth/signin/");
  };

  const goToHome = () => {
    navigate("/");
  };

  return (
    <section className={`dashboard_layout ${style.dashboard_layout}`}>
      <SideBar />
      <main className={`main_content ${style.main_content}`}>
        <header className={`main_header ${style.main_header}`}>
          <div className={style.logo} onClick={goToHome}>
            <img src={logo} className="d-md-block d-none" alt="Logo" />
            <img src={logo2} className="d-md-none d-block" alt="Logo" />
          </div>
          <SearchBar />
          {!location.pathname.includes("user") && (
            <button
              className="btn btn-primary"
              onClick={handleSignInClick}
              style={{ marginLeft: "auto" }}
            >
              Login
            </button>
          )}
        </header>
        <section className={style.scroll_content}>
          <div className="position-relative">
            <Outlet />
          </div>
        </section>
      </main>
    </section>
  );
}
