import React, { useState } from "react";
import "../../../assets/styles/modules/chatbot/chatbot-module.scss";
import ChatBotContent from "../../../components/ChatBot/ChatBotContent";
import ChatBotSidebar from "../../../components/ChatBot/ChatBotSidebar";

export default function ChatBot() {
  const [chat, setChat] = useState();
  const handleGetData = (data) => {
    setChat(data);
  };

  return (
    <div class="container">
      <ChatBotSidebar chat={chat} />

      <ChatBotContent handleGetData={handleGetData} />
    </div>
  );
}
