import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { formatTime } from "../../config/utils";
import { api } from "../../customAxios";

export default function ChatBotContent(props) {
  const { handleGetData } = props;
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([]);
  const { chat_id } = useParams();
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  const chatContentRef = useRef(null);

  useEffect(() => {
    const fetchChat = async () => {
      try {
        const response = await api.get(`/chats/${chat_id}`);
        setMessages(response.data.chat.conversations);
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };
    if (chat_id) {
      fetchChat();
    } else {
      setMessages([]);
    }
  }, [chat_id]);

  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [messages]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = async () => {
    if (inputValue.trim() === "") return;
    setIsLoading(true);
    const payload = {
      type: "Q",
      message: inputValue,
      chat_id: chat_id ? chat_id : null,
    };

    try {
      const aiResponse = await api.post(`chats/conversation`, payload);
      const newMessages = aiResponse.data.chat.conversations;
      if (!chat_id) {
        handleGetData(aiResponse.data.chat);
        navigate(`/user/chat-bot/${aiResponse.data.chat.id}`);
      }
      setMessages((prevMessages) => [...prevMessages, ...newMessages]);
      setIsLoading(false);
      setInputValue("");
    } catch (error) {
      console.error("Error sending message:", error);
      setIsLoading(false);
    }
  };

  return (
    <main className="chat-section">
      <div className="inner-chat-section">
        <div className="chat-header">
          <div className="chat-title">Staketune AI</div>
        </div>
        <div className="chat-content" ref={chatContentRef}>
          {messages.map((message, index) => (
            <div key={index} className="message">
              <div className="user-info">
                {message.type === "Q" && !message.image ? (
                  <div className="avatar-container">
                    {userInfo?.name
                      ? userInfo?.name.slice(0, 2).toUpperCase()
                      : "?"}
                  </div>
                ) : (
                  <div className="avatar-container">
                    <img src={logo} alt="Logo" className="avatar-image" />
                  </div>
                )}

                <div className="our-message">
                  <div className="time-align">
                    <h4>
                      {message.type === "Q" ? userInfo?.name : "Staketune AI"}
                    </h4>
                    <span className="timestamp">
                      {formatTime(message.created_at)}
                    </span>
                  </div>
                  <p
                    className={`${message.type === "Q" ? "user-message" : ""}`}
                  >
                    {message.message}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="chat-footer-input">
          <div className="chat-input">
            <input
              className="form-control"
              placeholder="Message Staketune AI..."
              value={inputValue}
              onChange={handleInputChange}
              onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
            />
            <i
              className={`${
                isLoading ? "icon-loader" : "icon-arrow-up"
              } send-message`}
              aria-hidden="true"
              onClick={!isLoading ? handleSendMessage : null}
            ></i>
          </div>
        </div>
      </div>
    </main>
  );
}
