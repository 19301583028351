import { useEffect, useRef, useState } from "react";
import style from "../../assets/styles/modules/dashboard/SideBar.module.scss";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { api } from "../../customAxios";

export default function SideBar() {
  const [isActive, setIsActive] = useState(false);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const isAuthenticated = localStorage.getItem("token");

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await api.get("/profile");
        localStorage.setItem("user", JSON.stringify(response.data.user));
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };

    fetchUserInfo();
  }, []);

  const handleToggle = () => {
    setIsActive(!isActive);
    document.body.classList.toggle("sidebar_collapse", !isActive);
  };

  const handleLinkClick = () => {
    setIsActive(false);
    document.body.classList.remove("sidebar_collapse");
  };

  useEffect(() => {
    if (isActive) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActive]);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsActive(false);
      document.body.classList.remove("sidebar_collapse");
    }
  };

  const handleClickLogout = () => {
    localStorage.clear();
    navigate("/auth/signin");
  };

  return (
    <div
      ref={sidebarRef}
      className={`${style.main_sidebar} ${isActive ? style.collapse : ""}`}
    >
      <button className={style.togglebtn} onClick={handleToggle}>
        <i className="icon-align-left"></i>
      </button>
      <div className={style.sidewrap}>
        <div className={style.logo}>
          <img src="/images/logo2.png" alt="" />
        </div>
        <div className={style.menu}>
          <ul>
            <div className={style.list}>
              {!isAuthenticated && (
                <>
                  <li className={splitLocation[0] === "" ? style.active : ""}>
                    <Link to="/" onClick={handleLinkClick}>
                      <span className={style.bgicon}>
                        <i className={`${style.icon_Home} icon-home1`}></i>
                      </span>{" "}
                      Home
                    </Link>
                  </li>
                </>
              )}

              {isAuthenticated && (
                <>
                  <li
                    className={
                      splitLocation[2] === "dashboard" ? style.active : ""
                    }
                  >
                    <Link to="/user/dashboard/" onClick={handleLinkClick}>
                      <span className={style.bgicon}>
                        <i className={`${style.icon_Home} icon-home1`}></i>
                      </span>{" "}
                      Home
                    </Link>
                  </li>
                  <li
                    className={
                      splitLocation[1] === "portfolio" ? style.active : ""
                    }
                  >
                    <Link to="/portfolio" onClick={handleLinkClick}>
                      <span className={style.bgicon}>
                        <i className="icon-chart"></i>
                      </span>{" "}
                      Portfolio
                    </Link>
                  </li>
                  <li
                    className={
                      splitLocation[1] === "profile" ? style.active : ""
                    }
                  >
                    <Link to="/profile" onClick={handleLinkClick}>
                      <span className={style.bgicon}>
                        <i className="icon-Group-11821"></i>
                      </span>{" "}
                      Profile
                    </Link>
                  </li>
                  <li
                    className={
                      splitLocation[1] === "notifications" ? style.active : ""
                    }
                  >
                    <Link to="/notifications" onClick={handleLinkClick}>
                      <span className={style.bgicon}>
                        <i className="icon-bell"></i>
                      </span>{" "}
                      Notifications
                    </Link>
                  </li>
                  <li
                    className={
                      splitLocation[2] === "chat-bot" ? style.active : ""
                    }
                  >
                    <Link to="/user/chat-bot" onClick={handleLinkClick}>
                      <span className={style.bgicon}>
                        <i className="icon-message-circle"></i>
                      </span>{" "}
                      Chat bot
                    </Link>
                  </li>
                  <li
                    className={
                      splitLocation[1] === "logout" ? style.active : ""
                    }
                  >
                    <Link to="/auth/signin" onClick={handleClickLogout}>
                      <span className={style.bgicon}>
                        <i className="icon-logout-3"></i>
                      </span>{" "}
                      Logout
                    </Link>
                  </li>
                </>
              )}
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}
