import moment from "moment";

export function formatNumber(num) {
    if (num >= 1000000) {
        return Math.floor(num / 1000000) + 'M';
    } else if (num >= 1000) {
        return Math.floor(num / 1000) + 'K';
    } else {
        return num?.toString();
    }
}

const channelConfig = {
    Spotify: {
      metric1Label: "Plays",
      metric2Label: "Total Followers",
      getGraphDataX: (item) => [
        new Date(item.date).getTime(),
        item.monthly_listeners_current || 0,
      ],
      getGraphDataY: (item) => [
        new Date(item.date).getTime(),
        item.followers_total || 0,
      ],
    },
    YouTube: {
      metric1Label: "Views",
      metric2Label: "Subscribers",
      getGraphDataX: (item) => [
        new Date(item.date).getTime(),
        item.video_views_total || 0,
      ],
      getGraphDataY: (item) => [
        new Date(item.date).getTime(),
        item.subscribers_total || 0,
      ],
    },
    Deezer: {
      metric1Label: "Plays",
      metric2Label: "Followers",
      getGraphDataX: (item) => [
        new Date(item.date).getTime(),
        item.views_total || 0,
      ],
      getGraphDataY: (item) => [
        new Date(item.date).getTime(),
        item.followers_total || 0,
      ],
    },
    SoundCloud: {
      metric1Label: "Plays",
      metric2Label: "Followers",
      getGraphDataX: (item) => [
        new Date(item.date).getTime(),
        item.streams_total || 0,
      ],
      getGraphDataY: (item) => [
        new Date(item.date).getTime(),
        item.followers_total || 0,
      ],
    },
    TikTok: {
      metric1Label: "Total Videos",
      getGraphDataX: (item) => [
        new Date(item.date).getTime(),
        item.videos_total || 0,
      ],
    },
    Instagram: {
      metric1Label: "",
      metric2Label: "Followers",
      getGraphDataX: () => [],
      getGraphDataY: (item) => [
        new Date(item.date).getTime(),
        item.followers_total || 0,
      ],
    },
    default: {
      metric1Label: "",
      metric2Label: "Followers",
      getGraphDataX: () => [],
      getGraphDataY: (item) => [
        new Date(item.date).getTime(),
        item.followers_total || 0,
      ],
    },
  };
 /**
 * Processes channel data based on the filtered channel name.
 * @param {string} filteredChannelName - The name of the filtered channel.
 * @param {object} data - The data object containing historical data.
 * @returns {object} - An object containing processed data for playlists and reach, along with metric labels.
 */
export const processChannelData = (filteredChannelName, data) => {
    if (!data || !data.data) {
      console.error('Invalid data provided.');
      return {
        playlistsData: [],
        playlistReachData: [],
        metric1Label: "",
        metric2Label: ""
      };
    }
  
    const history = data.data;
    const config = channelConfig[filteredChannelName] || channelConfig.default;
  
    const playlistsData = history.map(config.getGraphDataX);
    const playlistReachData = history.map(config.getGraphDataY);
  
    const metric1Label = config.metric1Label;
    const metric2Label = config.metric2Label;
  
    return {
      playlistsData,
      playlistReachData,
      metric1Label,
      metric2Label
    };
  };
  
export const  formatPlatformName = (name) => {
  // Replace underscores with spaces
  const formattedName = name.replace(/_/g, ' ');
  // Capitalize the first letter of each word
  return formattedName.replace(/\b\w/g, char => char.toUpperCase());
}
export const formateDate = (date) => {
  const formateDate = new Date(date);
  const option = { month: "short", day: "numeric" };
  return formateDate.toLocaleDateString("en-US", option);
};
export const getTimeAgo = (date) => {
  const momentDate = moment.utc(date);
  const now = moment.utc();
  const diffInSeconds = now.diff(momentDate, "seconds");

  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    min: 60,
    sec: 1,
  };

  for (const [unit, seconds] of Object.entries(intervals)) {
    const diff = Math.floor(diffInSeconds / seconds);

    if (diff >= 1) {
      return `${diff} ${unit}${diff > 1 ? "s" : ""} ago`;
    }
  }

  return "just now";
};
export const formatTime = (date) => {
  return moment.utc(date).local().format("hh:mm A");
};