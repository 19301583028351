import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import style from "../../../assets/styles/modules/prelogin/prelogin-layout.module.scss";
import Password from "../../../components/Form/Password";
import constants from "../../../config/constants";
import { api } from "../../../customAxios";

export default function Signup() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
    .required("Please enter valid name")
    .matches(/^\w+/, "Please enter valid name")
    .max(128, "The name cannot be more than 128 characters"),
    email: Yup.string()
      .required("Please enter valid email address")
      .matches(constants.EMAIL_REGEX, "Please enter valid email address"),
    password: Yup.string()
      .required("Please enter your password")
      .min(6, "The password must be at least 6 characters"),
    password_confirmation: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {},
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    await api
      .post("/register", data)
      .then((response) => {
        localStorage.setItem("token", response.data.user.token);
        navigate('/user/dashboard');
        toast.success("User register successfully.");
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <form
        className={`${style.form_wrapper} prelogin`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className={style.form_title}>Hi, Register Now</h1>

        <div className="form-group">
          <label className="text-left">Name</label>
          <input
            type="text"
            className={`form-control  ${
              errors.name ? style['is-invalid_boder'] : ""
            }`}
            placeholder="Enter name"
            {...register("name")}
          />
          {errors.name && (
            <span className={`${style["danger-color"]} error-msg`}>
              {errors.name.message}
            </span>
          )}
        </div>
        <div className="form-group">
          <label className="text-left">Email</label>
          <input
            type="text"
            className={`form-control  ${
              errors.email ? style['is-invalid_boder'] : ""
            }`}
            placeholder="Enter Email Address"
            {...register("email")}
          />
          {errors.email && (
            <span className={`${style["danger-color"]} error-msg`}>
              {errors.email.message}
            </span>
          )}
        </div>
        <Password
          id="txtPassword"
          name="txtPassword"
          label="Password"
          placeholder="Enter Password"
          {...register("password")}
          errorMessage={errors.password?.message}
        />
        <Password
          id="txtConfirmPassword"
          name="txtConfirmPassword"
          label="Confirm Password"
          placeholder="Confirm Password"
          {...register("password_confirmation", {
            required: "Password Confirmation is required",
          })}
          errorMessage={errors.password_confirmation?.message}

        />
        <div className={`form-group ${style.forgotWrapper}`}>
          <div className="form-check checkbox">
            <input
              type="checkbox"
              name="chkTerms"
              id="chkTerms"
              className="custom-checkbox"
            />
            <label htmlFor="chkTerms" className="form-check-label">
              I agree to the Terms & Conditions and Privacy Policy
            </label>
          </div>
        </div>
        <p className="text-danger">{errorMessage}</p>
        <div className="form-group">
          <button
            className="btn btn-primary"
            type="submit"
            disabled={!isDirty || !isValid || isLoading}
          >
            {isLoading ? "Please wait..." : "Signup"}
          </button>
        </div>
      </form>
      <p className={`${style.form_para} text-center`}>
        Don&apos;t have an account?{" "}
        <Link to="/auth/signin" className="fw-700 text-primary">
          Login
        </Link>
      </p>
    </>
  );
}
