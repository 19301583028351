import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./auth/login";
import PreLoginLayout from "../../components/Layouts/PreLoginLayout";
import Signup from "./auth/signup";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import Home from "./home";

const PublicPortal = () => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Routes>
      <Route
          path="/"
          element={
            <DashboardLayout />
          }
        >
          <Route
            path=":artistId?/:channelId?"
            element={
              <React.Suspense fallback={<></>}>
                <Home />
              </React.Suspense>
            }
          />
        </Route>
        <Route path="/auth" element={<>{<PreLoginLayout />}</>}>
          <Route
            path="signin"
            element={
              <React.Suspense fallback={<></>}>
                <Login />
              </React.Suspense>
            }
          />
          <Route
            path="signup"
            element={
              <React.Suspense fallback={<></>}>
                <Signup />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default PublicPortal;
