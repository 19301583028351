import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { api } from "../../customAxios";
import ArtistDetailPanel from "./ArtistDetailPanel";
import { formatPlatformName } from "../../config/utils";
import NoResultFound from "../../assets/images/no_result_found.svg";

const PopularTab = ({
  artists,
  hidedata,
  isLoading,
  setIsLoading
}) => {
  const navigate = useNavigate();
  const { artistId, channelId } = useParams();
  const [artistInfo, setArtistInfo] = useState({});
  const isAuthenticated = localStorage.getItem("token");
  const [selectedChannnelId, setSelectedChannelId] = useState("");

  useEffect(() => {
    const getArtistInfo = async () => {
      setIsLoading(true);
      const apiEndpoint = isAuthenticated 
      ? `artists/${artistId}` 
      : `public/artists/${artistId}`
      await api
        .get(apiEndpoint)
        .then((response) => {
          setSelectedChannelId(response?.data?.channels[0].id ? response?.data?.channels[0].id : channelId);
          // if(isAuthenticated) {
          //   navigate(`/user/dashboard/${artistId}/${response?.data?.channels[0].id ? response?.data?.channels[0].id : channelId}`)
          // }else {
          //   navigate(`/${artistId}/${response?.data?.channels[0].id ? response?.data?.channels[0].id : channelId}`)
          // }
          setArtistInfo(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    };
    if(artistId) {
      getArtistInfo();
    }
  }, [artistId]);
  const handleArtistClick = (artistId) => {
    if(isAuthenticated) {
      navigate(`/user/dashboard/${artistId}/${selectedChannnelId}`);
    }else {
      navigate(`/${artistId}/${selectedChannnelId}`);
    }
  };

  const handlePlatformClick = (platformId) => {
    setSelectedChannelId(platformId);
    if(isAuthenticated) {
      navigate(`/user/dashboard/${artistId}/${platformId}`);
    }else {
      navigate(`/${artistId}/${platformId}`);
    }
  };



  return (
<div className="artistlist">
  {artists.length > 0 ? (
    <Tabs className="sidetab">
      <div className="listhide">
        <TabList>
          {artists?.map((artist) => (
            <Tab onClick={() => handleArtistClick(artist.id)} key={artist.id}>
              <div
                className={
                  artist.id === artistId
                    ? "selected-artist"
                    : "un-selected-artist"
                }
              >
                <img src={artist?.profile_pic} alt={artist.name} />
                <span>{artist.name}</span>
              </div>
            </Tab>
          ))}
        </TabList>
      </div>

      {artists?.map((artist, index) => (
        <TabPanel key={index}>
          <div className="content">
            <div className="backbtn" onClick={hidedata}>
              <i className="icon-arrow-left"></i>
            </div>
            <Tabs className="">
              <TabList className="tabplat custom-tab">
                {artistInfo?.channels?.map((platform) => (
                  <Tab
                    onClick={() => handlePlatformClick(platform.id)}
                    key={platform.name}
                  >
                    {formatPlatformName(platform.name)}
                  </Tab>
                ))}
              </TabList>
              {artistInfo?.channels?.map((platform) => (
                <TabPanel key={platform.name}>
                  <ArtistDetailPanel
                    isLoading={isLoading}
                    artistInfo={artistInfo}
                    setArtistInfo={setArtistInfo}
                    setIsLoading={setIsLoading}
                    selectedArtistId={artistId}
                    selectedChannnelId={selectedChannnelId}
                    channels={artistInfo?.channels}
                  />
                </TabPanel>
              ))}
            </Tabs>
          </div>
        </TabPanel>
      ))}
    </Tabs>
  ) : (
    <span className="no-artists-message">
      <img src={NoResultFound} alt="" />
      You don't have any artists in your watchlist.</span>
  )}
</div>

  );
};

export default PopularTab;
