import React, { useEffect } from "react";
import style from "../../assets/styles/modules/prelogin/prelogin-layout.module.scss";
import { useNavigate, Outlet } from "react-router-dom/dist";
import logo from "../../assets/images/logo2.png";

export default function PreLoginLayout() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("authpage");
  }, []);

  const gotoArtistspage = () => {
    navigate("/");
  };

  return (
    <section className={style.prelogin}>
      <div className={style.inner}>
        <div className={style.formWrapper}>
          <div className={style.innerWrapper}>
            <div
              className={style.logo}
              onClick={gotoArtistspage}
              style={{ cursor: "pointer" }}
            >
              <img src={logo} alt="Logo" />{" "}
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
}
