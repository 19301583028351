import { useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "../../assets/styles/modules/dashboard/SearchBar.module.scss";
import { api } from "../../customAxios";

export default function SearchBar() {
  const [isActive, setIsActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState([]);
  const navigate = useNavigate();
const isAuthenticated = localStorage.getItem('token');
  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const handleSearch = async (e) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value.trim() === "") {
      setResults([]);
      return;
    }

    try {
    const apiEndpoint = isAuthenticated 
      ? `/artists?keyword=${value}` 
      : `public/artists?keyword=${value}`
      const response = await api.get(apiEndpoint);
      const data = response.data;
      console.log("data", data);
      if (data) {
        setResults(data || []);
      } else {
        setResults([]);
      }
    } catch (error) {
      console.error(error);
      setResults([]);
    }
  };

  const goToArtists = (artistId) => {
    const isAuthenticated = localStorage.getItem("token") !== null && localStorage.getItem("token") !== "";
    const url = isAuthenticated ? `/user/dashboard/${artistId}` : `/${artistId}`;
    navigate(url);
    setIsActive(false);
  };



  return (
    <>
      <div className={`${style.searchbar} ${isActive ? style.active : ''}`}>
        <div className={`${style.input_group}`}>
          <input
            type="text"
            placeholder="Search Here"
            value={searchValue}
            onChange={handleSearch}
            onFocus={handleToggle}  // Show dropdown on focus
          />
          <span className={`${style.input_group_text}`} onClick={handleToggle}>
            <i className="icon-search"></i>
            {/* <img src="/images/search-normal.svg" alt="" /> */}
          </span>
        </div>
        {isActive && (
          <div className={style.dropdown}>
            {results.map((result) => (
              <div
                key={result.id}
                onClick={() => goToArtists(result.id)}
                className={style.dropdown_item}
              >
                <div className={style.avatar_container}>
                  <img src={result.profile_pic} alt={result.name} className={style.avatar} />
                </div>
                <span className={style.artist_name}>{result.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
