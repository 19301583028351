import React, { useState, useEffect, forwardRef } from "react";

const Password = forwardRef((props, ref) => {
  const [isShowPass, setIsShowPass] = useState(false);
  const [isAuthPage, setIsAuthPage] = useState(false);

  useEffect(() => {
    const hasAuthPageClass = document.body.classList.contains('authpage');
    setIsAuthPage(hasAuthPageClass);
  }, []);

  const className = `${!isAuthPage ? 'form-label' : 'form-floating'}`;

  return (
    <>
      <style jsx global>{`
        .password-icon {
          right: 15px;
          left: auto !important;
          cursor: pointer;
        }
        .form-control {
          padding-left: 18px !important;
        }
      `}</style>
      <div className="form-group">
        <label className="text-left">{props.label}</label>
        <div className="icon-field">
          <input
            type={isShowPass ? "text" : "password"}
            className={`form-control ${props.errorMessage ? 'is-invalid_boder' : ''}`}
            placeholder={props.placeholder || "Password"}
            name={props.name}
            id={props.id}
            maxLength={20}
            ref={ref}
            {...props}
          />
          <span
            className={`${isShowPass ? 'icon-eye' : 'icon-eye-off'} icon password-icon`}
            onClick={() => setIsShowPass(!isShowPass)}
          ></span>
          {props.errorMessage && <span className={'danger-color'}>{props.errorMessage}</span>}
        </div>
      </div>
    </>
  );
});

export default Password;
