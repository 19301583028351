import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { platforms } from "../../config/PlatformImages";
import ArtistChart from "../Charts/ArtistChart";
import { api } from "../../customAxios";
import { toast } from "react-toastify";

const ArtistDetailPanel = ({
  isLoading,
  setIsLoading,
  artistInfo,
  setArtistInfo,
  selectedChannnelId,
  selectedPage,
  channels,
}) => {
  const isAuthenticated = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const [chartData, setChartData] = useState();
  const [isLoading2, setIsLoading2] = useState(false);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const refreshArtist = searchParams.get('refresh');
  const handleBuyEarlyClick = async (artistId) => {
    if (!isAuthenticated) {
      navigate(
        `/auth/signin?redirectTo=/user/dashboard/${artistId}/${selectedChannnelId}`
      );
      return;
    }
  
    try {
      setIsLoading2(true);
      const payload = {
        artist_id: artistId,
      };
  
      await api
        .post("/artist-add-as-favorite", payload)
        .then((response) => {
          setArtistInfo((prevState) => ({
            ...prevState,
            favorite: true,
          }));
          setIsLoading2(false);
          toast.success(response?.data.message);
        })
        .catch((error) => {
          setIsLoading2(false);
          toast.error(error.response?.data?.message);
        })
        .finally(() => {
          setIsLoading2(false);
        });
    } catch (error) {
      console.error("Error adding artist as favorite:", error);
    }
  };
  const handleRefreshArtist = async (artistId) => {
    try {  
      setIsRefreshLoading(true);
      await api
        .post(`refresh/artists/${artistId}`)
        .then((response) => {
          toast.success(response?.data.message);
          setIsRefreshLoading(false);
        })
        .catch((error) => {
          setIsRefreshLoading(false);
          toast.error(error.response?.data?.message);
        })
        .finally(() => {
          setIsLoading2(false);
        });
    } catch (error) {
      console.error("Error adding artist as favorite:", error);
    }
  };
  

  const handleGetChartData = (data) => {
    if(data) {
      setChartData(data?.iconData);
    } else {
      setChartData();
    }
  };
   const filteredChannels = channels.filter(channel => channel.id === selectedChannnelId);
   const channelIcons = filteredChannels.map((channel) => {
     const platform = platforms.find(platform => platform.name === channel.name);
     return platform ? (
       <div key={channel.id} className="channel-icon">
         <img
           src={platform.icon}
           alt={platform.label}
           width={35}
           height={35}
           style={{ marginRight: '8px' }}
         />
         <span style={{marginTop: "8px"}}>{platform.label}</span>
       </div>
     ) : null;
   });
 
  return (
    <div className="card card-lg artist-card mt-lg-3">
       {channelIcons}
      <div className="card-body">
         
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "125px",
              }}
            >
              <ScaleLoader
                color="#000000"
                loading={isLoading}
                height={40}
                width={5}
                radius={2}
              />
            </div>
            <p
              style={{ marginTop: "10px", fontSize: "16px", color: "#000000" }}
            >
              Just a second, we're almost there...
            </p>
          </div>
        ) : (
          <>
            <div className="artistdata">
              <div className="proimg">
                <img src={artistInfo?.profile_pic} alt="" />
                <h4 className="name">{artistInfo?.name}</h4>
              </div>
              {artistInfo.favorite === false && (
                <a
                  href="#"
                  className="btn btn-primary"
                  onClick={() => handleBuyEarlyClick(artistInfo?.id)}
                  disabled={isLoading2}
                >
                  {isLoading2 ? "Buy Early...." : "Buy Early"}
                </a>
               )}
              {refreshArtist && (
                <a
                  href="#"
                  className="btn btn-primary"
                  style={{marginLeft: "5px"}}
                  disabled={isRefreshLoading}
                  onClick={() => handleRefreshArtist(artistInfo?.id)}
                >
                   {isRefreshLoading ? "Refresh...." : "Refresh"}
                </a>
               )} 
            </div>
            <ArtistChart
              selectedChannnelId={selectedChannnelId}
              artistId={artistInfo?.id}
              handleGetChartData={handleGetChartData}
            />
            <div className="listdata">
              {Array.isArray(chartData) && chartData.length > 0 && (
                <>
                  {chartData.map((data, index) => (
                    <div className="cards" key={index}>
                      <h2 className="title">{data?.text}</h2>
                      <p className="status">{data?.secondaryText}</p>
                      <p className="total">{data?.count}</p>
                    </div>
                  ))}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ArtistDetailPanel;
