import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ukIcon from "../../assets/images/uk.png";
import usaIcon from "../../assets/images/usa.png";
import PopularTab from "../../components/TabPanels/PopularTab";
import { api } from "../../customAxios";

export default function Dashboard() {
  const { artistId, channelId } = useParams();
  const [artistsData, setArtists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState("popular");
  const [scrollHidden, setScrollHidden] = useState(false);
  const navigate = useNavigate();

  const addClass = () => {
    const elements = document.getElementsByClassName("listhide");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("hide");
    }
  };

  const removeClass = () => {
    const elements = document.getElementsByClassName("listhide");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("hide");
    }
  };

  const showScrollbar = () => {
    document.body.style.overflow = "auto";
    setScrollHidden(false);
  };

  const hidedata = () => {
    showScrollbar();
    removeClass();
  };

  useEffect(() => {
    const getListOfArtists = async () => {
      setIsLoading(true);
      await api
        .get(`/public/artists`)
        .then((response) => {
          setArtists(response.data);
          navigate(
            `/${response.data && response.data[0].id}/${channelId ? channelId : ""}`
          );
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    };
    getListOfArtists();
  }, []);

  const handleTabSelectAsync = async (index) => {
    const sources = [
      "popular",
      "us-stock",
      "uk-stock",
      "african-stock",
    ];
    const selected = sources[index];
    setSelectedPage(selected);
    setArtists(artistsData);
  };

  const handleTabSelect = (index) => {
    handleTabSelectAsync(index).catch((error) => {
      console.error("Error handling tab select:", error);
    });
  };

  return (
    <Tabs className="sidetab" onSelect={handleTabSelect}>
      <div className="maintab listhide">
        <TabList>
          <Tab>
            <i className="icon-upstair"></i> Popular
          </Tab>
          <Tab>
            <img src={usaIcon} alt="" /> US Stock
          </Tab>
          <Tab>
            <img src={ukIcon} alt="" /> UK Stock
          </Tab>
          <Tab>
            <i className="icon-map1"></i> African Artiste
          </Tab>
        </TabList>
      </div>
      <TabPanel>
        <PopularTab
          artists={artistsData}
          hidedata={hidedata}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </TabPanel>
      <TabPanel>
        <PopularTab
          artists={artistsData}
          hidedata={hidedata}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </TabPanel>
      <TabPanel>
        <PopularTab
          artists={artistsData}
          hidedata={hidedata}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </TabPanel>
      <TabPanel>
        <PopularTab
          artists={artistsData}
          hidedata={hidedata}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </TabPanel>
    </Tabs>
  );
}
