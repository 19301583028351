// src/assets/platformIcons.js

import appleMusicIcon from '../assets/images/apple.png';
import deezerIcon from '../assets/images/deezer.png';
import instagramIcon from '../assets/images/insta.png';
import soundcloudIcon from '../assets/images/soundcloud.png';
import spotifyIcon from '../assets/images/spotify.png';
import tidalIcon from '../assets/images/tidal.png';
import tiktokIcon from '../assets/images/tiktok.png';
import youtubeIcon from '../assets/images/youtube.png';
import shazamIcon from '../assets/images/shazam.png';
import ituneIcon from '../assets/images/itune.jpeg';
import tracklistspngIcon from '../assets/images/1001Tracklistspng.png';
import facebookIcon from '../assets/images/facebook.png';

export const platforms = [
  { name: "spotify", icon: spotifyIcon, label: "Spotify" },
  { name: "youtube", icon: youtubeIcon, label: "YouTube" },
  { name: "apple_music", icon: appleMusicIcon, label: "Apple Music" },
  { name: "deezer", icon: deezerIcon, label: "Deezer" },
  { name: "soundcloud", icon: soundcloudIcon, label: "SoundCloud" },
  { name: "tidal", icon: tidalIcon, label: "Tidal" },
  { name: "tiktok", icon: tiktokIcon, label: "TikTok" },
  { name: "instagram", icon: instagramIcon, label: "Instagram" },
  { name: "shazam", icon: shazamIcon, label: "Shazam" },
  { name: "itunes", icon: ituneIcon, label: "iTunes" },
  { name: "tracklist", icon: tracklistspngIcon, label: "Tracklist" },
  { name: "facebook", icon: facebookIcon, label: "Facebook" },


];
