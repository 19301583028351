import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { api } from "../../customAxios";
import { toast } from "react-toastify";

export default function ChatBotSidebar(props) {
  const { chat } = props;
  const [chats, setChats] = useState([]);
  const [todayChats, setTodayChats] = useState([]);
  const [previousChats, setPreviousChats] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [isTodayExpanded, setIsTodayExpanded] = useState(true);
  const [isPreviousExpanded, setIsPreviousExpanded] = useState(true);
  const navigate = useNavigate();
  const { chat_id } = useParams();
  const dropdownRef = useRef(null);
  const deleteButtonRef = useRef(null);

  useEffect(() => {
    if (chat) {
      addChat(chat);
    }
  }, [chat]);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await api.get("/chats");
        const allChats = response.data;
        updateChatLists(allChats);
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };

    fetchChats();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !deleteButtonRef.current?.contains(event.target)
      ) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleGoToChartView = (chatId) => {
    navigate(`/user/chat-bot/${chatId == null ? "" : chatId}`);
  };

  const toggleDropdown = (chatId) => {
    setDropdownOpen(dropdownOpen === chatId ? null : chatId);
  };

  const handleDeleteChat = async (chatId) => {
    try {
      setDropdownOpen(null);
      await api.delete(`/chats/${chatId}`);
      const updatedChats = chats.filter((chat) => chat.id !== chatId);
      navigate(`/user/chat-bot`);
      updateChatLists(updatedChats);
      toast.success("Chat deleted successfully!");
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  const addChat = (newChat) => {
    setChats((prevChats) => {
      const updatedChats = [newChat, ...prevChats];
      updateChatLists(updatedChats);
      return updatedChats;
    });
  };

  const updateChatLists = (allChats) => {
    const today = moment().startOf("day");
    const updatedTodayChats = allChats.filter((chat) =>
      moment(chat.created_at).isSame(today, "day")
    );
    const updatedPreviousChats = allChats.filter((chat) =>
      moment(chat.created_at).isBetween(moment().subtract(7, "days"), today)
    );

    setChats(allChats);
    setTodayChats(updatedTodayChats);
    setPreviousChats(updatedPreviousChats);
  };

  const toggleTodaySection = () => {
    setIsTodayExpanded(!isTodayExpanded);
  };

  const togglePreviousSection = () => {
    setIsPreviousExpanded(!isPreviousExpanded);
  };

  return (
    <aside className="sidebar">
      <div className="logo">
        <img src={logo} className="user-info-image" alt="Logo" />
        <h4>Staketune AI</h4>
        <i className="icon-edit" onClick={() => handleGoToChartView(null)}></i>
      </div>
      <div className="menu">
        <div className="today_section">
          <h2>Today</h2>
          <div className="dropdown_count">
            <p>{todayChats.length} Total</p>
            <i
              className={`icon-chevron-${isTodayExpanded ? "down" : "right"}`}
              onClick={toggleTodaySection}
            ></i>
          </div>
        </div>
        {isTodayExpanded && (
          <ul>
            {todayChats.map((chat) => (
              <li key={chat.id} className={chat.id === chat_id ? "active" : ""}>
                <span onClick={() => handleGoToChartView(chat.id)}>
                  {chat.title}
                </span>
                <div className="chat-options" ref={dropdownRef}>
                  <i
                    className="icon-more-horizontal"
                    onClick={() => toggleDropdown(chat.id)}
                  ></i>
                  {dropdownOpen === chat.id && (
                    <div className="dropdown-menu">
                      <p
                        ref={deleteButtonRef}
                        onClick={() => handleDeleteChat(chat.id)}
                      >
                        Delete
                      </p>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      {previousChats.length > 0 && (
        <div className="previous-menu">
          <div className="today_section">
            <h2>Previous 7 Days</h2>
            <div className="dropdown_count">
              <p>{previousChats.length} Total</p>
              <i
                className={`icon-chevron-${
                  isPreviousExpanded ? "down" : "right"
                }`}
                onClick={togglePreviousSection}
              ></i>
            </div>
          </div>
          {isPreviousExpanded && (
            <ul>
              {previousChats.map((chat) => (
                <li
                  key={chat.id}
                  className={chat.id === chat_id ? "active" : ""}
                  onClick={() => handleGoToChartView(chat.id)}
                >
                  <span>{chat.title}</span>
                  <div className="chat-options" ref={dropdownRef}>
                    <i
                      className="icon-more-horizontal"
                      onClick={() => toggleDropdown(chat.id)}
                    ></i>
                    {dropdownOpen === chat.id && (
                      <div className="dropdown-menu">
                        <p
                          ref={deleteButtonRef}
                          onClick={() => handleDeleteChat(chat.id)}
                        >
                          Delete
                        </p>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </aside>
  );
}
