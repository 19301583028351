import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import ChatBot from "./chat-bot/chatBot";
// Global Page Routes
const Dashboard = React.lazy(() => import("./dashboard/dashboard"));

const PrivatePortal = () => {

  return (
    <React.Suspense fallback={<></>}>
      <Routes>
        <Route
          path="/"
          element={
            <DashboardLayout />
          }
        >
          <Route
            path="/dashboard/:artistId?/:channelId?"
            element={
              <React.Suspense fallback={<></>}>
                <Dashboard />
              </React.Suspense>
            }
          />
          <Route
            path="/chat-bot/:chat_id?"
            element={
              <React.Suspense fallback={<></>}>
                <ChatBot />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default PrivatePortal;
